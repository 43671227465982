// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-plant-js": () => import("./../../../src/components/Plant.js" /* webpackChunkName: "component---src-components-plant-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-collections-calatheas-index-js": () => import("./../../../src/pages/collections/calatheas/index.js" /* webpackChunkName: "component---src-pages-collections-calatheas-index-js" */),
  "component---src-pages-collections-palms-index-js": () => import("./../../../src/pages/collections/palms/index.js" /* webpackChunkName: "component---src-pages-collections-palms-index-js" */),
  "component---src-pages-collections-succulents-index-js": () => import("./../../../src/pages/collections/succulents/index.js" /* webpackChunkName: "component---src-pages-collections-succulents-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-fertilizing-index-js": () => import("./../../../src/pages/learn/fertilizing/index.js" /* webpackChunkName: "component---src-pages-learn-fertilizing-index-js" */),
  "component---src-pages-learn-humidity-index-js": () => import("./../../../src/pages/learn/humidity/index.js" /* webpackChunkName: "component---src-pages-learn-humidity-index-js" */),
  "component---src-pages-learn-index-js": () => import("./../../../src/pages/learn/index.js" /* webpackChunkName: "component---src-pages-learn-index-js" */),
  "component---src-pages-learn-light-index-js": () => import("./../../../src/pages/learn/light/index.js" /* webpackChunkName: "component---src-pages-learn-light-index-js" */),
  "component---src-pages-learn-pests-index-js": () => import("./../../../src/pages/learn/pests/index.js" /* webpackChunkName: "component---src-pages-learn-pests-index-js" */),
  "component---src-pages-learn-propagation-index-js": () => import("./../../../src/pages/learn/propagation/index.js" /* webpackChunkName: "component---src-pages-learn-propagation-index-js" */),
  "component---src-pages-learn-pruning-index-js": () => import("./../../../src/pages/learn/pruning/index.js" /* webpackChunkName: "component---src-pages-learn-pruning-index-js" */),
  "component---src-pages-learn-repotting-index-js": () => import("./../../../src/pages/learn/repotting/index.js" /* webpackChunkName: "component---src-pages-learn-repotting-index-js" */),
  "component---src-pages-learn-watering-index-js": () => import("./../../../src/pages/learn/watering/index.js" /* webpackChunkName: "component---src-pages-learn-watering-index-js" */),
  "component---src-pages-plants-groups-index-js": () => import("./../../../src/pages/plants/groups/index.js" /* webpackChunkName: "component---src-pages-plants-groups-index-js" */),
  "component---src-pages-plants-index-js": () => import("./../../../src/pages/plants/index.js" /* webpackChunkName: "component---src-pages-plants-index-js" */),
  "component---src-pages-request-index-js": () => import("./../../../src/pages/request/index.js" /* webpackChunkName: "component---src-pages-request-index-js" */)
}

